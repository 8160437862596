<script>
import { mapActions, mapGetters } from "vuex";
import axiosTOTP from "../../api/axiosTOTP";
export default {
  components: {
    art: () => import("../art/art.vue"),
    MoInput: () => import("@/components/input/MoInput.vue"),
  },
  data() {
    return {
      data: null,
      user: {
        email: "",
        password: "",
      },
    };
  },
  computed: {
    ...mapGetters("user", { getToken: "getToken" }),
  },
  methods: {
    ...mapActions("user", { loginMethod: "loginMethod" }),

    async setAuthenticate() {
      const _user = {
        email: this.user.email.trim(),
        password: this.user.password.trim(),
      };
      if (!_user.email || !_user.password) {
        this.$toast.error("Por favor revise los datos ingresados");
        return;
      }
      const app = this.$route.query.app || process.env.VUE_APP_KEY_NAME;
      const payload = { user: _user, app };
      const isLogued = await this.loginMethod(payload);

      if (isLogued) {
        const { user } = isLogued;
        this.loginSuccess(user);
        localStorage.removeItem("startStreaming");
        localStorage.removeItem("alertNotification");
      } else {
        this.$toast.error(this.$store.state.user.error);
      }
    },

    handleKeyPress(event) {
      const { key, target } = event;
      if (key === "Enter") {
        const name = target.name;
        if (name === 'user') {
          this.focusNextInput("password");
        } else if (name === 'password') {
          this.checkPassword();
        }
      }
    },

    focusNextInput(fieldName) {
      const passwordInput = document.querySelector(`[name="${fieldName}"]`);
      if (passwordInput) {
        passwordInput.focus();
      }
    },

    checkPassword() {
      if (this.user.password.length > 0) {
        this.setAuthenticate();
      } else {
        this.$toast.error("La contraseña no puede ser vacía.");
      }
    },

    cleanInput() {
      if (this.error) {
        this.error = "";
      }
    },

    async loginSuccess({ is2FAEnabled, role, area }) {
      const {
        data: { areas },
      } = await this.get2FAOptionalAreas();

      const areaRequires2FA = this.areaRequires2FA(area, areas);

      localStorage.setItem("is2FAEnabled", is2FAEnabled);

      if (areaRequires2FA) {
        localStorage.setItem("skip2FA", true);
        this.$router.push({ name: "2FA" });
      } else {
        localStorage.setItem(process.env.VUE_APP_TOKEN_NAME, this.getToken);
        this.$router.push({ path: "/apps" });
      }
    },

    async get2FAOptionalAreas() {
      const headers = { [process.env.VUE_APP_TOKEN_NAME]: this.getToken };
      return await axiosTOTP({ headers }).get("/areas");
    },

    areaRequires2FA(idArea, areas) {
      return areas.findIndex((area) => area._id === idArea) !== -1;
    },
  },
};
</script>

<template>
  <div class="login fade-in">
    <div class="login__container">
      <div class="login__groupInput">
        <div class="login__inputWrapper">
          <iconic class="login__userIcon" name="userInput" />
          <input class="login__input" type="text" placeholder="Usuario" v-model="user.email" autocomplete="off"
            @keyup="handleKeyPress($event)" name="user" />
        </div>
      </div>
      <div class="login__groupInput">
        <div class="login__inputWrapper">
          <iconic class="login__userIcon" name="passwordInput" />
          <input class="login__input" type="password" placeholder="Contraseña" v-model="user.password"
            autocomplete="off" @keydown="handleKeyPress($event)" name="password" />
        </div>
      </div>

      <a class="login__restorePass" @click="$router.push('/restorepass')">¿Olvidaste tu contraseña?</a>
      <button class="login__submit" @click="setAuthenticate">Ingresar</button>
    </div>
  </div>
</template>

<style lang="scss">
.login {
  height: 100%;
  text-align: center;
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;

  &__container {
    height: 100%;
    width: 100%;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    gap: 30px;
  }

  &__groupInput {
    width: 90%;
    display: flex;
    flex-direction: column;
    align-items: start;
    gap: 8px;
  }

  &__inputWrapper {
    width: 100%;
    border: 1px solid #e9edef;
    border-radius: 8px;
    display: flex;
    padding: 8px 10px;
    transition: all ease-in 0.3s;

    &:focus-within,
    &:focus-within .restorePass__userIcon {
      transition: all ease-in 0.3s;
      border-color: $primary-color;
      color: $primary-color;
    }

    &__userIcon {
      color: #bfbfbf;
    }

    &:focus-within,
    &:focus-within .login__userIcon {
      transition: all ease-in 0.3s;
      border-color: $primary-color;
      color: $primary-color;
    }
  }

  &__input {
    width: 100%;
    border: none;
    padding: 0 15px;
    font-family: $newFont_regular;

    &:disabled {
      color: #000;
    }
  }

  &__restorePass {
    font-family: $newFont_regular;
    color: #272727;
    cursor: pointer;
    transition: all ease-in 300;

    &:hover {
      color: $lipstick;
    }
  }

  &__submit {
    width: 90%;
    background-color: $lipstick;
    border: none;
    border-radius: 8px;
    height: 40px;
    color: $white;
    transition: all ease-in 0.3s;
    font-family: $newFont_semiBold;
    margin-bottom: 24px;

    &:hover {
      opacity: 0.5;
    }
  }

  .art {
    &[dir="top"] {
      top: -50px;
    }

    .line {
      &:nth-child(4) {
        width: 50px;
        height: 50px;
        left: 25px;
        top: 0px;
      }
    }
  }

  @include tabletWidth() {
    &__groupInput {
      width: 70%;
    }

    &__submit {
      width: 70%;
    }
  }
}
</style>
